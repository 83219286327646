@import '../../styles/imports';

.CustomModal {

    .modal-content {
        background-color: $white;

        .modal-title {
            h2 {
                margin-bottom: 0;
                color: $secondary-color !important;
            }
        }

        h2 {
            font-size: 24px !important;
            color: $primary-color !important;
        }
    }
}