@import '../../styles/imports';


.program-pdf {
    background: #f6f6f6;

    h2 {
        color: $secondary-color;
        padding-top: 40px;
    }

    .pdf-container {
        display: flex;
        justify-content: center; /* Center horizontally */
        align-items: center;     /* Center vertically */
        height: 100%; 

        .button-container {
            width: 100% !important;
            margin-bottom: 40px;
            margin-top: 20px;

            .btn {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                border-color: transparent;
                background-color: $primary-color;
                font-size: 24px;
                text-transform: uppercase;
                font-weight: bold;
                line-height: 0.95;

                a {
                    text-decoration: none;
                    color: $white;
                    padding: 10px;
                }

                h3 {
                    margin: 0 !important;
                    color: $white;
                }

                &.whatsapp {
                    background-color: $whatsapp-green;
                }

                .svg-inline--fa {
                    margin-right: 3px;
                    font-size: 18px;
                }
            }

            .smaller {
                color: $secondary-color;
                font-size: smaller;
            }
        }

        .pdf-file {
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }
}