@import '../../styles/imports';

.services {
    background: #f6f6f6;
    #features {
        padding: 40px 0;

        h2 {
            color: $secondary-color;
        }

        p {
            font-size: 18px;
        }

        .section-title {
            h2 {
                // Style the first word (Traeme)
                span.first-word {
                    color: $primary-color; /* Set primary color for the first word */
                }

                // Style the last word (Taxi)
                span.last-word {
                    color: $secondary-color; /* Set secondary color for the last word */
                }
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    bottom: -4px;
                    left: 50%;
                    transform: translateX(-50%);
                    background:  linear-gradient(to right, $secondary-color 0%, #4E6E96 100%);
                    height: 4px;
                    width: 150px;
                }
            }
        }

        .icon-center {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 40px;
        }
        
        .icon-container {
            img {
                margin-bottom: 20px;
                width: 200px;
                height: 200px;
                border-radius: 50%;
                background: linear-gradient(to right, $secondary-color 0%, #4E6E96 100%);
                box-shadow: 10px 10px 10px rgba(0,0,0,.05);
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

